import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useMutation, useQuery } from "@apollo/react-hooks"
import Throbber from "./../components/throbber"
import {
  formatServerError,
  isValidEmail,
  transformSegmentsAndBUs,
} from "../utilities/helpers"
import LayoutContainer from "../components/layout"
import brandLogo from "./../assets/brands/te/logo.svg"
import { SIGN_UP } from "../gql/login"
import {
  APP_ROUTES,
  AUTH_TOKEN_STORE_KEY,
  CHANGE_PASSWORD_STORE_KEY,
  LANGUAGES,
  LANGUAGES_FLAG,
} from "../constants"
import { Trans, useTranslation, withTranslation } from "react-i18next"
import countries from "../assets/countries.json"
import { GET_BUSINESSUNITS } from "../gql/businessUnit"
import { GET_SITES } from "../gql/sites"

const Signup = ({ location }) => {
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [country, setCountry] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [teamAccount, setTeamAccount] = useState(false)
  const [signUp, { loading, error }] = useMutation(SIGN_UP)
  const [customError, setCustomerError] = useState("")
  const { t: translate } = useTranslation()
  const [segments, setSegments] = useState(null)
  const [segment, setSegment] = useState("")
  const [businessUnit, setBusinessUnit] = useState("")
  const [businessUnitsList, setBusinessUnitsList] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES["en-US"])
  const urlParams = new URLSearchParams(location.search)
  const companyId = urlParams.get("company")
  const [siteLocationList, setSiteLocationList] = useState([])
  const [siteLocation, setSiteLocation] = useState("")

  const { loading: isLoading, data: businessUnitsData } = useQuery(
    GET_BUSINESSUNITS,
    {
      variables: {
        companyId: companyId || "",
      },
    }
  )

  const { loading: isLoadingSites, data: sitesData } = useQuery(GET_SITES)

  React.useEffect(() => {
    if (
      localStorage.getItem(AUTH_TOKEN_STORE_KEY) ||
      sessionStorage.getItem(AUTH_TOKEN_STORE_KEY)
    ) {
      // User is already logged in, send them to homebase
      navigate(APP_ROUTES.home)
    }
  }, [])

  useEffect(() => {
    if (country && !isLoadingSites) {
      let filterSites =
        sitesData &&
        sitesData.getSites.filter(site => site.countryCode === country)
      setSiteLocation("")
      setSiteLocationList(filterSites)
    }
  }, [country, isLoadingSites])

  const handleAcceptTerms = event => {
    const { checked } = event.currentTarget
    setTermsAccepted(checked)
  }

  const handleTeamAccount = event => {
    const { checked } = event.currentTarget
    setTeamAccount(checked)
  }

  const onSubmit = event => {
    event.preventDefault()
    setCustomerError("")
    const urlParams = new URLSearchParams(location.search)
    const company = urlParams.get("company")
    if (!company) {
      setCustomerError("Company not found")
      return
    }
    signUp({
      variables: {
        email,
        userName,
        password,
        name,
        company,
        country,
        teamAccount,
        businessUnit,
        sites: siteLocation,
        language: selectedLanguage,
      },
      update: setAuthToken,
    })
  }

  const setAuthToken = (
    cache,
    {
      data: {
        signUp: { token, changePassword },
      },
    }
  ) => {
    cache.writeData({
      data: {
        authToken: token,
        changePassword: changePassword,
      },
    })
    sessionStorage.setItem(AUTH_TOKEN_STORE_KEY, token)
    localStorage.setItem(CHANGE_PASSWORD_STORE_KEY, changePassword)
    setTimeout(() => {
      navigate(APP_ROUTES.home)
    }, 200)
  }

  const isValidInputs =
    isValidEmail(email) &&
    confirmPassword === password &&
    password.length &&
    termsAccepted

  useEffect(() => {
    if (businessUnitsData && businessUnitsData.getBusinessUnits) {
      const transformedData = transformSegmentsAndBUs(
        businessUnitsData.getBusinessUnits
      )
      setSegments(transformedData)
    }
  }, [businessUnitsData && businessUnitsData.getBusinessUnits])

  useEffect(() => {
    if (segment) {
      const arrIndex = segments.findIndex(
        singleSegment => singleSegment.segment === segment
      )
      if (segments[arrIndex] && segments[arrIndex].businessUnits) {
        setBusinessUnitsList(segments[arrIndex].businessUnits)
      }
    }
  }, [segment])

  return (
    <LayoutContainer seoTitle="Signup" path={location.pathname}>
      <div className="row">
        <div className="col l8 offset-l2 s12">
          <div className="center-align margin--bottom-40">
            <h1 className="big-title uppercase no-margin">
              <Trans i18nKey="sign_up.title" />
            </h1>
          </div>
        </div>
        <div className="col l4 offset-l4 m8 offset-m2 s12">
          <form className="form" onSubmit={onSubmit} noValidate>
            <div className="form__image-wrapper">
              <img className="form__top-image" src={brandLogo} alt="Login" />
            </div>
            <label className="sr-only" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Name"
              className="form__text-input"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <label className="sr-only" htmlFor="email">
              Username
            </label>
            <input
              id="username"
              type="text"
              placeholder="Username"
              className="form__text-input"
              onChange={e => setUserName(e.target.value)}
              value={userName}
            />
            <label className="sr-only" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              className="form__text-input"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <label className="sr-only" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              type="password"
              placeholder="Password"
              className="form__text-input"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <label className="sr-only" htmlFor="password">
              Confirm Password
            </label>
            <input
              id="confirm-password"
              type="password"
              placeholder="Confirm Password"
              className="form__text-input"
              onChange={e => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <label className="sr-only" htmlFor="country">
              <Trans i18nKey="sign_up.country_label" />
            </label>
            <select
              id="country"
              className="form__text-input form__drop-down" // Combine the styles for text input and dropdown
              onChange={e => setCountry(e.target.value)}
              value={country}
            >
              <option value="" disabled>
                {translate("sign_up.select_country")}
              </option>
              {countries.map(country => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
            <select
              id="location"
              className="form__text-input form__drop-down" // Combine the styles for text input and dropdown
              onChange={e => setSiteLocation(e.target.value)}
              value={siteLocation}
              disabled={country.length === 0}
            >
              <option value="" disabled>
                {translate("sign_up.select_site")}
              </option>
              {!isLoadingSites &&
                siteLocationList &&
                siteLocationList.map(site => (
                  <option key={site.id} value={site.id}>
                    {site.location}
                  </option>
                ))}
            </select>
            <select
              id="segment"
              className="form__text-input form__drop-down" // Combine the styles for text input and dropdown
              onChange={e => setSegment(e.target.value)}
              value={segment}
            >
              <option value="" disabled>
                {translate("sign_up.select_segment")}
              </option>
              {!isLoading &&
                segments &&
                segments.length &&
                segments.map(singleSegment => (
                  <option
                    key={singleSegment.segment}
                    value={singleSegment.segment}
                  >
                    {singleSegment.segment}
                  </option>
                ))}
            </select>
            <select
              id="businessUnit"
              className="form__text-input form__drop-down" // Combine the styles for text input and dropdown
              onChange={e => setBusinessUnit(e.target.value)}
              value={businessUnit}
              disabled={!segment}
            >
              <option value="" disabled>
                {translate("sign_up.select_business_unit")}
              </option>
              {!isLoading &&
                businessUnitsList &&
                businessUnitsList.map(businessUnit => (
                  <option key={businessUnit.id} value={businessUnit.id}>
                    {businessUnit.name}
                  </option>
                ))}
            </select>
            <select
              id="language"
              className="form__text-input form__drop-down" // Combine the styles for text input and dropdown
              onChange={e => setSelectedLanguage(e.target.value)}
              value={selectedLanguage}
            >
              {Object.entries(LANGUAGES_FLAG).map(([code, label]) => (
                <option key={code} value={code}>
                  {label}
                </option>
              ))}
            </select>
            <div className="form__actions">
              <div style={{ width: "100%" }}>
                <div className="form__checkbox-wrapper margin--bottom-8 form__remember-me-wrapper">
                  <a
                    className="form__reset-password small-text cursor-pointer"
                    onClick={() => {
                      navigate(APP_ROUTES.login)
                    }}
                  >
                    Already have an account?
                  </a>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <input
                    className="form__checkbox-input"
                    type="checkbox"
                    id="team"
                    onChange={handleTeamAccount}
                  />
                  <label
                    className="form__checkbox-label small-text"
                    htmlFor="team"
                  >
                    <div className="form__checkbox-wrapper">
                      <span> Register me as a team account </span>
                    </div>
                  </label>
                </div>
                <div>
                  <input
                    className="form__checkbox-input"
                    type="checkbox"
                    id="terms"
                    onChange={handleAcceptTerms}
                  />
                  <label
                    className="form__checkbox-label small-text"
                    htmlFor="terms"
                  >
                    <div className="form__checkbox-wrapper">
                      <span>
                        {" "}
                        <Trans i18nKey={"login.terms"} />{" "}
                      </span>
                      <a
                        className="link--small"
                        href="https://www.iubenda.com/terms-and-conditions/73329296"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey={"login.terms_text_link"} />
                      </a>
                      <span>
                        <Trans i18nKey={"login.terms_text_and"} />{" "}
                      </span>
                      <a
                        className="link--small"
                        href="https://www.iubenda.com/privacy-policy/73329296"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey={"login.privay_policy"} />
                      </a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="center-align">
              <input
                type="submit"
                className="button"
                value="Register"
                disabled={!isValidInputs}
              />
            </div>
            <div className="center-align">
              {loading && <Throbber loadingText="Signing up..." relative />}
              {error && <p>{formatServerError(error.message)}</p>}
              {customError && customError.length && <p>{customError}</p>}
            </div>
          </form>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default withTranslation()(Signup)
